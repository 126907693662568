import React from "react";
import * as styles from "./HeroImages.module.scss";
import { default as RightEllipse } from "src/assets/RightEllipse.svg";
import { default as BottomEllipse } from "src/assets/BottomEllipse.svg";
import { default as ProdBottomEllipse } from "src/assets/EllipseRightProd.svg";
import { StaticImage } from "gatsby-plugin-image";

type EmployeeObject = {
    type: string,
    text: string,
    textColor: string,
    imgUrl: string,
    ellipseRight: string,
    ellipseDown: string
}

const HeroImages = ({ employeeType }: any) => {
    if (employeeType.type) {
        return (
            <div className={styles.HeroImagesWrapper}>
                {employeeType.type == "production" ?
                    <ProdBottomEllipse fill={employeeType.ellipseRight} className={styles.ProdRightEllipse} />
                    : <RightEllipse fill={employeeType.ellipseRight} className={styles.RightEllipse} />}
                <BottomEllipse fill={employeeType.ellipseDown} className={`${styles.BottomEllipse} ${employeeType.type == "production" ? "left" : ""}`} />

                {employeeType.type == "physical" && <StaticImage imgStyle={{ objectFit: "contain" }} className={styles.GatsbyImageWrapper} src='../../../../../../images/physicalWorker.png' alt="physical worker" />}
                {employeeType.type == "production" && <StaticImage imgStyle={{ objectFit: "contain" }} className={styles.GatsbyImageWrapper} src='../../../../../../images/productionWorker.png' alt="production worker" />}
                {employeeType.type == "field" && <StaticImage imgStyle={{ objectFit: "contain" }} className={styles.GatsbyImageWrapper} src='../../../../../../images/fieldWorker.png' alt="field worker" />}
                {employeeType.type == "young" && <StaticImage imgStyle={{ objectFit: "contain" }} className={styles.GatsbyImageWrapper} src='../../../../../../images/youngWorker.png' alt="young worker" />}

            </div>
        );
    } else {
        return null;
    }


}

export default HeroImages;
