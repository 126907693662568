import * as React from "react";
import NewHero from "src/components/pages/index/sections/NewHero/NewHero";
import Paragraph from "src/components/pages/index/sections/paragraph/Paragraph";
import * as styles from './Header.module.scss';

const Header = ({ children }: any) => {
    return (
        <div className={styles.Header}>
            {children}
        </div>
    );
};

export default Header;
