import React, { useEffect, useState, useRef } from "react";
import Button from "src/components/common/Button/Button";
import HeroImages from "./HeroImages/HeroImages";
import * as styles from "./NewHero.module.scss";
import Typewriter from 'typewriter-effect';
import { Link } from 'gatsby';
import useWindowSize from "src/components/hooks/useWindowSize";

const employeeTypes = [
    {
        type: "physical",
        imgUrl: '/physicalWorker.png',
        ellipseRight: "#287ABE",
        ellipseDown: "#F6CE48"
    },
    {
        type: "production",
        imgUrl: '/productionWorker.png',
        ellipseRight: "#287ABE",
        ellipseDown: "#75529D"
    },
    {
        type: "field",
        imgUrl: '/fieldWorker.png',
        ellipseRight: "#f64b42",
        ellipseDown: "#9B50EB"
    },
    {
        type: "young",
        imgUrl: '/youngWorker.png',
        ellipseRight: "#287ABE",
        ellipseDown: "#F6CE48"
    },

];

const NewHero = ({ pageName }: any) => {

    const { width } = useWindowSize();

    const [employeeType, setEmployeeType] = useState({
        type: "",
        imgUrl: '',
        ellipseRight: "",
        ellipseDown: ""
    });

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * employeeTypes.length);
        setEmployeeType(employeeTypes[randomIndex])

    }, [])

    return (
        <section className={styles.NewHeroWrapper}>
            <div className={styles.ContentWrapper}>
                {pageName == "main" ?
                    <>
                        <div className={styles.LeftWrapper}>

                            <h1>Przyspiesz komunikację <br /> z pracownikami
                                <Typewriter options={{
                                    strings: ['<span style="color: #F5BA09;font-weight: 500;font-family: inherit;">fizycznymi</span>', '<span style="color: #FF4B41;font-weight: 500;font-family: inherit;">produkcyjnymi</span>', '<span style="color: #0082F0;font-weight: 500;font-family: inherit;">terenowymi</span>'],
                                    autoStart: true,
                                    delay: 75,
                                    pauseFor: 100,
                                    loop: true,
                                    cursor: "",
                                    wrapperClassName: styles.animatedText
                                }}
                                /></h1>
                            <p>
                                Skróć dystans między biurem a rozproszoną załogą. Przekaż dowolną informację pojedynczemu pracownikowi lub całej firmie - natychmiast!
                            </p>
                            <Link to="/contact"><Button>Porozmawiajmy</Button></Link>
                        </div>

                        {width && width >= 1043 && <HeroImages employeeType={employeeType} />}
                    </>


                    : pageName == "onboarding" ?
                        <>
                            <div className={styles.LeftWrapper}>

                                <h1>Onboarding i Offboarding</h1>
                                <p>
                                    Wdrażaj pracowników w strukturę firmy i kulturę organizacyjną jeszcze zanim pojawią się na stanowisku.                            </p>
                            </div>
                            {width && width >= 1043 && <HeroImages employeeType={employeeType} />}
                        </>


                        : pageName == "communication" ?
                            <>
                                <div className={styles.LeftWrapper}>

                                    <h1>Wewnętrzna platforma komunikacyjna</h1>
                                    <p>
                                        Przekazuj pracownikom codzienne informacje szybko i bezpośrednio i pozwól im na to samo w drugą stronę!                            </p>
                                </div>
                                {width && width >= 1043 && <HeroImages employeeType={employeeType} />}
                            </>



                            : pageName == "organizer" ?
                                <>
                                    <div className={styles.LeftWrapper}>

                                        <h1>Firmowy organizer </h1>
                                        <p>
                                            Wysyłaj polecenia do rozproszonych biur i pracowników. Otrzymuj powiadomienia, gdy zostaną wykonane.                           </p>
                                    </div>
                                    {width && width >= 1043 && <HeroImages employeeType={employeeType} />}
                                </>

                                : pageName == "branding" ?
                                    <>
                                        <div className={styles.LeftWrapper}>

                                            <h1>Employer Branding </h1>
                                            <p>
                                                Wyróżnij się w branży nowoczesnym podejściem do pracowników i komunikacji firmowej.                           </p>
                                        </div>
                                        {width && width >= 1043 && <HeroImages employeeType={employeeType} />}
                                    </>

                                    :
                                    null}
            </div>
        </section>
    );

}

export default NewHero;
