import React from "react";
import * as styles from "./Paragraph.module.scss";

export default function Paragraph({ title, desc }) {

  return (
    <section className={styles.ContentWrapper}>
      <h2>{title}</h2>
      <p>
        {desc}
      </p>
    </section>
  );
}